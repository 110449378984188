.container {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000000;
}

.square-button {
  background-color: transparent;
  border: 0px none;
  font-size: 20px;
  height: 100%;
  width: 100%;
}
