body {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  /* padding: 10px; */
  /* overflow-x: hidden; */
}

#root {
  height: 100%;
  width: 100%;
}
